import axios from "axios";
import { BASE_URL, DevConfig } from "../constants/constants";
import { logoutAction } from "../redux/actions/AuthActions";
import { getTokenSelector } from "../redux/selectors/AuthSelectors";
import { store } from "../redux/store";

const authAxios = axios.create({
    baseURL: BASE_URL,
    timeout: 60000,
});

authAxios.interceptors.request.use(async (config) => {
    const state = store.getState();
    const token = getTokenSelector(state);
    config.headers["Authorization"] = `Bearer ${token}`;
    if (DevConfig.ENABLE_CONSOLE_LOGS)
        return config;
});

authAxios.interceptors.response.use(
    (res) => {
        return Promise.resolve(res?.data)
    },
    async (error) => {
        if (error?.response?.status === 401) store.dispatch(logoutAction(true))
        if (axios?.isCancel(error)) return Promise.reject({ ...error, isCancel: true })
        else return Promise.reject(error?.response)
    }
)

export default authAxios;
