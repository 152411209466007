import authAxios from "./authAxios";
import EndPoints from "./EndPoints";
import withoutAuthAxios from "./withoutAuthAxios";

// Auth
export const loginService = (data) =>
  withoutAuthAxios.post(EndPoints.login, data);

//Get Dashboard
export const getDashboardService = () => authAxios.get(EndPoints.getDashboard);

//Get Products List
export const getProductListService = (page = 1, filter = null, limit = 10) =>
  authAxios.get(EndPoints.products, {
    params: {
      page,
      limit,
      filter,
    },
  });

//Update product
export const updateProductService = (data) =>
  authAxios.post(EndPoints.updateProduct, data);

//Frequently Used Products
export const frequentlyUsedProductService = (
  page = 1,
  filter = null,
  limit = 10
) =>
  authAxios.post(EndPoints.frequentlyUsedProduct, {
    page,
    limit,
    filter,
  });

// Favourite List
export const favouriteProductListService = (
  page = 1,
  filter = null,
  limit = 10
) =>
  authAxios.get(EndPoints.favouriteProductList, {
    params: {
      page,
      limit,
      filter,
    },
  });

//Get User Management
export const getUserMangagementService = (
  page = 1,
  filter = null,
  limit = 10
) =>
  authAxios.post(EndPoints.getUser, {
    page,
    limit,
    filter,
  });

//Delete User Management by id
export const deleteUserManagementService = (id) =>
  authAxios.delete(`${EndPoints.deleteUserById}/${id}`);

//Add User
export const addUserMangagementService = (data) =>
  authAxios.post(EndPoints.addUser, data);

//Update user
export const updateUsersService = (data) =>
  authAxios.put(EndPoints.updateUsers, data);

//Branch Dropdown
export const branchDropdownService = (data) =>
  authAxios.get(EndPoints.branchDropdown, data);

//Roles Dropdown
export const getRolesDropdownService = (data) =>
  authAxios.get(EndPoints.getRolesDropdown, data);

//Salesman Dropdown
export const getSalesmanService = (id) =>
  authAxios.get(`${EndPoints.getSalesman}/${id}`);

//Quoatation Details
export const getQuotationDetailsService = (id) =>
  authAxios.get(`${EndPoints.getQuotationDetails}/${id}`);

//Update Quotation Quote
export const updateQuotationQuoteService = (data) =>
  authAxios.post(EndPoints.updateQuotationQuote, data);

//Quotation Export
export const quotationExcelExportService = (data) =>
  authAxios.post(EndPoints.quotationExcelExport, data);

//Quotation Export
export const quotationExcelExport2Service = (data) =>
  authAxios.post(EndPoints.quotationExcelExport2, data);

//Quotation Export
export const quotationExportService = (data) =>
  authAxios.post(EndPoints.exportQuotation, data);

//Product Export
export const productsExportService = (data) =>
  authAxios.get(EndPoints.productsExport, data);

//Add Or Remove Favourite Product
export const addOrRemoveFavouriteProductService = (
  product_id = null,
  status = 0
) =>
  authAxios.post(EndPoints.addOrRemoveFavouriteProduct, { product_id, status });

//Get Branch List
export const getBranchListService = (page = 1, filter = null, limit = 10) =>
  authAxios.post(EndPoints.getBranches, {
    page,
    limit,
    filter,
  });

// Delete Branch List
export const deleteBranchService = () =>
  authAxios.delete(`${EndPoints.deleteBranch}`);

//Add Branch
export const addBranchService = (data) =>
  authAxios.post(EndPoints.addBranches, data);

//Add Branch
export const updateBranchService = (data) =>
  authAxios.post(EndPoints.updateBranch, data);

// Get Quotation List
export const getQuotationService = (page = 1, filter = null, limit = 10) =>
  authAxios.post(EndPoints.quotationList, {
    page,
    limit,
    filter,
  });

//add Quotation
export const addQuotationService = (data, signal) =>
  authAxios.post(EndPoints.addQuotation, data, { signal });

// Get Quotation Client Details
export const getQuotationClientService = (id) =>
  authAxios.get(`${EndPoints.getQuotationClient}/${id}`);

//Update Quotation Client Details
export const updateQuotationClientService = (body) =>
  authAxios.post(EndPoints.updateQuotationClient, body);
export const createQuotationClientService = (body) =>
  authAxios.post(EndPoints.createQuotationClient, body);

// Get Quotation Settings Details
export const getQuotationSettingService = (id) =>
  authAxios.get(`${EndPoints.getQuotationSetting}/${id}`);
// Create Quotation Setting
export const createQuotationSettingService = () =>
  authAxios.post(`${EndPoints.createQuotationSetting}`);

// Create Blank Quotation
export const createBlankQuotationService = () =>
  authAxios.post(EndPoints.createBlankQuotation, {
    quotation_status: "no status",
    version: 1,
  });

// Update Quotation Settings Details
export const updateQuotationSettingService = (body) =>
  authAxios.post(EndPoints.updateQuotationSetting, body);

// Quotation Versions
export const getQuotationVersionService = (
  id,
  page = 1,
  filter = 20,
  limit = 10
) =>
  authAxios.get(`${EndPoints.getQuotationVersion}/${id}`, {
    page,
    limit,
    filter,
  });

//Create Quotation Version
export const createQuotationVersionService = (data) =>
  authAxios.post(EndPoints.createQuotationVersion, data);

// Delete Product
export const deleteProductService = (id) =>
  authAxios.delete(`${EndPoints.deleteProduct}/${id}`);

// Delete Quotation by Id
export const deleteQuotationService = (id) =>
  authAxios.delete(`${EndPoints.deleteQuotation}/${id}`);

// Get Roles List
export const getRolesListService = () =>
  authAxios.get(`${EndPoints.getRolesApi}`);

//Change Password
export const changePasswordService = (data) =>
  authAxios.post(EndPoints.changePassword, data);

//UPDATE Password
export const updateUserPasswordService = (data) =>
  authAxios.post(EndPoints.updatePassword, data);

//Forgot Password
export const forgotPasswordService = (data) =>
  authAxios.post(EndPoints.forgotPassword, data);

//Logout
export const logoutService = () => authAxios.post(EndPoints.logout);
