import React, { useState } from "react";
import CustomToast from "../../../toastify/CustomToast";
import { forgotPasswordService } from "../../../services/Services";
import CustomLoader from "../../../customComponents/customLoader/CustomLoader";
import { Link } from "react-router-dom";
import { allRoutes } from "../../../routes/routes";

const ForgotPassword = () => {
  const [formData, setformData] = useState([]);
  const [loading, setLoading] = useState(false);

  //   On Input Data Change
  const onInputDataChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  // On Forgot Press
  const onForgotPassword = (e) => {
    e.preventDefault();
    if (!formData.email) return CustomToast.error("Enter email");
    if (!isEmailValid(formData.email))
      return CustomToast.error("Enter a valid email address");
    setLoading(true);

    forgotPasswordService({
      email: formData.email,
    })
      .then((res) => {
        CustomToast.success(res?.message);
      })
      .catch((error) => {
        CustomToast.error(error?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="col-12 p-0">
      <div className="container container-padding">
        <div className="row">&nbsp;</div>
        <div className="row" style={{ marginTop: "9pc" }}>
          <div className="col-md-12 dashboard-card-display">
            <div className="card" style={{ margin: "auto" }}>
              <h4 className="my-4 mx-1" style={{ alignContent: "center" }}>
                Forgot Password
              </h4>
              <CustomLoader loaderPosition={"top"} full visible={loading} />
              <div className="card-body login-card-body ">
                <form className="p-3">
                  <div className="input-group mb-3">
                    <span className="label">
                      <b>Email</b>
                    </span>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email"
                      value={formData.email}
                      name="email"
                      onChange={onInputDataChange}
                    />
                  </div>
                  <div className="col-12">
                    <div className="forgot_pwd">
                      <Link
                        to={allRoutes.login}
                        className="forgot_pwd_link"
                        style={{ float: "right" }}
                      >
                        Remember Password? Login
                      </Link>
                    </div>
                  </div>
                  <div className="col-12 row mt-5">
                    <div
                      className="col-12 text-left"
                      style={{ textAlignLast: "center" }}
                    >
                      <button
                        type="submit"
                        className="btn btn-primary px-4 m-3"
                        onClick={onForgotPassword}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
