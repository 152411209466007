import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginAction } from "../../../redux/actions/AuthActions";
import { getUserCredentials } from "../../../redux/selectors/AuthSelectors";
import CustomToast from "../../../toastify/CustomToast";

const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setformData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const defaultCredentials = useSelector(getUserCredentials);

  useEffect(() => {
    if (defaultCredentials?.email && defaultCredentials?.password) {
      setformData({
        email: defaultCredentials?.email,
        password: defaultCredentials?.password,
      });
      setRememberMe(true);
    }
  }, [defaultCredentials]);

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  //   On Input Data Change
  const onInputDataChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  // On Login Press
  const onLoginHandler = (e) => {
    e.preventDefault();
    if (!isEmailValid(formData.email))
      return CustomToast.error("Enter a valid email address");
    if (!formData.email) return CustomToast.error("Enter email");
    if (!formData.password) return CustomToast.error("Enter password");

    dispatch(loginAction(formData, rememberMe)).then(() => {
      navigate("/");
    });
  };

  return {
    formData,
    loading,
    onInputDataChange,
    onLoginHandler,
    passwordVisibility,
    togglePasswordVisibility,
    setRememberMe,
    rememberMe,
  };
};

export default useLogin;
