import { useNavigate } from "react-router-dom";
import { sendDataToReducer } from "../../helpers/CommonHelpers";
import { loginService } from "../../services/Services";
import { logoutService } from "../../services/Services";
import CustomToast from "../../toastify/CustomToast";
import {
  RESET_USER_DATA,
  SET_TOKEN,
  SET_USER_CREDENTIALS,
  SET_USER_DATA,
} from "../reducers/AuthReducer";
import { RESET_STORE } from "../types/ReduxTypes";
import { getUserDataSelector } from "../selectors/AuthSelectors";

// Login
export const loginAction = (formData, rememberMe) => (dispatch) =>
  new Promise((resolve, reject) => {
    loginService({ email: formData?.email, password: formData?.password })
      .then((res) => {
        sendDataToReducer(dispatch, SET_USER_DATA, res?.data);
        sendDataToReducer(dispatch, SET_TOKEN, res?.token);
        if (rememberMe) {
          sendDataToReducer(dispatch, SET_USER_CREDENTIALS, {
            email: formData?.email,
            password: formData?.password,
          });
        }
        CustomToast.success(res?.message);
        resolve(res?.data);
      })
      .catch((error) => {
        console.error(error);
        CustomToast.error(error?.data?.message);
        reject(error);
      });
  });

// Logout
export const logoutAction =
  (forceLogout = false) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(RESET_USER_DATA());
      if (!forceLogout) logoutService();
      resolve(true);
    });

export const updateUserDataAction = (uData) => (dispatch, getState) => {
  const state = getState();
  const userData = getUserDataSelector(state);
  sendDataToReducer(dispatch, SET_USER_DATA, {
    ...userData,
    ...uData,
  });
};
