import { memo, useMemo } from "react";
import lottieFiles from "../../assets/lottieAnimation/lottieFiles";
import CustomLottie from "../customLottie/CustomLottie";

const CustomLoader = ({
  style,
  visible = false,
  full = false,
  opacity = 0.7,
  size = 70,
  loaderPosition = "center",
}) => {
  const position = useMemo(() => {
    switch (loaderPosition) {
      case "top":
        return "flex-start";
      case "bottom":
        return "flex-start";
      default:
        return "center";
    }
  }, [loaderPosition]);

  return (
    visible && (
      <div
        style={{
          display: "flex",
          alignItems: position,
          justifyContent: "center",
          paddingRight: 15,
          paddingLeft: 15,
          ...(full && {
            backgroundColor: `rgba(255,255,255,${opacity})`,
            position: "absolute",
            zIndex: 100,
            height: "100%",
            width: "100%",
            padding: 40,
          }),
          ...style,
        }}
      >
        <CustomLottie
          loop
          lottieJson={lottieFiles.loader}
          height={size}
          width={size}
        />
      </div>
    )
  );
};

export default memo(CustomLoader);
