import React from "react";
import { Link } from "react-router-dom";
import { allRoutes } from "../../../routes/routes";
import useLogin from "./useLogin";

const Login = () => {
  const {
    formData,
    onInputDataChange,
    onLoginHandler,
    loading,
    passwordVisibility,
    togglePasswordVisibility,
    setRememberMe,
    rememberMe,
  } = useLogin();

  return (
    <>
      <div className="hold-transition login-page">
        <div className="login-box">
          <div className="login-logo">
            <img src="dist/img/logo.png" className="img" />
          </div>
          <div className="card border-0">
            <div className="card-body login-card-body">
              <form onSubmit={onLoginHandler}>
                <div className="input-group mb-3">
                  <span className="label">Username</span>
                  {/* <input type="text" name="_token" value={csrf-Token} /> */}
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={formData.email}
                    name="email"
                    onChange={onInputDataChange}
                    style={{ borderRadius: "8px" }}
                  />
                </div>

                <div className="input-group mb-3 password-input-group">
                  <span className="label">Password</span>
                  <input
                    value={formData.password}
                    name="password"
                    type={passwordVisibility ? "text" : "password"}
                    className="form-control password-input"
                    placeholder="Password"
                    onChange={onInputDataChange}
                  />
                  <a
                    onClick={togglePasswordVisibility}
                    style={{
                      border: 0,
                      zIndex: 2,
                      marginTop: "18px",
                      cursor: "pointer",
                    }}
                  >
                    {passwordVisibility ? (
                      <i
                        className="fas fa-eye"
                        id="show_eye"
                        style={{ marginLeft: "-29px" }}
                      ></i>
                    ) : (
                      <i
                        className="fas fa-eye-slash"
                        style={{ marginLeft: "-29px" }}
                      ></i>
                    )}
                  </a>
                </div>

                <div className="row">
                  <div className="col-8">
                    <div className="icheck-primary">
                      <input
                        type="checkbox"
                        id="remember"
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                      />
                      <label htmlFor="remember" className="remember">
                        Remember Me
                      </label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="forgot_pwd">
                      <Link
                        to={allRoutes.forgotPassword}
                        className="forgot_pwd_link"
                      >
                        Forgot Password ?
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={onLoginHandler}
                      disabled={loading}
                    >
                      {loading ? "Logging In" : "Login"}
                    </button>
                  </div>
                  {/* /.col */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
