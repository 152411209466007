import "./App.css";
import Toastify from "./toastify";
import Router from "./routes";
import { ConfirmDialog } from "primereact/confirmdialog";

function App() {
    return (
        <>
            <Router />
            <Toastify />
            <ConfirmDialog />
        </>
    );
}

export default App;
