import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logoutAction } from "../../../redux/actions/AuthActions";
import {
  getActiveRoleSelector,
  getUserDataSelector,
} from "../../../redux/selectors/AuthSelectors";
import { logoutService } from "../../../services/Services";
import CustomToast from "../../../toastify/CustomToast";
import { allRoutes } from "../../../routes/routes";

const useHeader = () => {
  const [initials, setInitials] = useState("");
  const dispatch = useDispatch();
  const userData = useSelector(getUserDataSelector);
  const location = useLocation();
  const userRole = useSelector(getActiveRoleSelector);

  const dashboardClass =
    location.pathname === allRoutes.dashboard ? "activeClass" : "";
  const quotationClass =
    location.pathname === allRoutes.mainQuotation ? "activeClass" : "";
  const quotationDetailClass =
    location.pathname === allRoutes.quotation ? "activeClass" : "";
  const productClass = location.pathname === "/products" ? "activeClass" : "";
  const editProductClass =
    location.pathname === allRoutes.editProduct ? "activeClass" : "";
  const userManagementClass =
    location.pathname === allRoutes.userManagement ? "activeClass" : "";
  const importExportClass =
    location.pathname === allRoutes.importExport ? "activeClass" : "";

  const onLogoutPress = () => {
    dispatch(logoutAction()).finally(() => {
      window.location.replace("auth");
    });
  };

  const nameShortCode = useMemo(() => {
    const fullName = userData?.first_name?.split(" ");
    return `${fullName?.[0]?.[0] ?? ""}${fullName?.[1]?.[0] ?? ""}`;
  }, [userData?.first_name]);

  return {
    nameShortCode,
    onLogoutPress,
    userRole,
    userData,
    initials,
    quotationClass,
    location,
    userManagementClass,
    productClass,
    dashboardClass,
    importExportClass,
    quotationDetailClass,
    editProductClass,
  };
};

export default useHeader;
