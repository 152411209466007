import React from "react";
import { Link } from "react-router-dom";
import { ROLES } from "../../../constants/constants";
import { allRoutes } from "../../../routes/routes";
import useHeader from "./useHeader";

const Header = () => {
  const {
    onLogoutPress,
    userData,
    userRole,
    quotationClass,
    userManagementClass,
    productClass,
    dashboardClass,
    importExportClass,
    quotationDetailClass,
    editProductClass,
    nameShortCode,
  } = useHeader();

  return (
    <div className="col-12 p-0">
      <nav
        id="navbar_top"
        className="navbar navbar-expand-lg navbar-light navbar-bg"
      >
        <div className="container-fluid">
          <a href="#" className="navbar-brand">
            <img src="dist/img/logo.png" style={{ width: "116px" }} />
          </a>
          <button
            type="button"
            className="navbar-toggler"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav">
              <Link
                to={allRoutes.dashboard}
                className={`nav-item nav-link ${dashboardClass}`}
              >
                Dashboard
              </Link>
              <Link
                to={allRoutes.mainQuotation}
                className={`nav-item nav-link  ${quotationClass} ${quotationDetailClass}`}
              >
                Quotations
              </Link>
              <Link
                to={allRoutes.products}
                className={`nav-item nav-link  ${productClass} ${editProductClass} `}
              >
                Products
              </Link>
              {userRole == ROLES.ADMIN ? (
                <Link
                  to={allRoutes.userManagement}
                  className={`nav-item nav-link  ${userManagementClass}`}
                >
                  User Management
                </Link>
              ) : null}

              {userRole == ROLES.ADMIN ? (
                <Link
                  to={allRoutes.importExport}
                  className={`nav-item nav-link  ${importExportClass}`}
                >
                  Import/Export
                </Link>
              ) : null}
            </div>
            <div className="navbar-nav ms-auto dropdown">
              <a
                href="#"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                className="nav-item header-side-left-text  px-3 py-2"
              >
                {nameShortCode.toUpperCase()}
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <div className="col-md-12">
                  <a href="#" className="header-side-dropdown-text float-left">
                    {nameShortCode.toUpperCase()}
                  </a>
                  <span className="profile_dropdonw_name">
                    {userData?.first_name} {userData?.last_name}
                  </span>
                  <br />
                  <span className="profile_dropdonw_email">
                    {userData?.email}
                  </span>
                </div>
                <div className="dropdown-divider" />
                <Link
                  className="dropdown-item profile_dropdonw_menu_item"
                  to="/reset_password"
                >
                  {" "}
                  <i className="fas fa-user-shield profile_dropdown_menu_icons" />{" "}
                  Change Password
                </Link>
                <a
                  className="dropdown-item profile_dropdonw_menu_item"
                  href="#"
                  onClick={onLogoutPress}
                >
                  {" "}
                  <i className="fas fa-sign-out-alt profile_dropdown_menu_icons" />{" "}
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
