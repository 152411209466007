import { createSlice } from "@reduxjs/toolkit";

export const AuthInitialState = {
  defaultCredentials: [],
  userData: null,
  token: null,
};

const AuthSlice = createSlice({
  name: "auth",
  initialState: AuthInitialState,
  reducers: {
    SET_TOKEN: (state, action) => {
      state.token = action.payload;
    },
    SET_USER_DATA: (state, action) => {
      state.userData = action.payload;
    },
    SET_USER_CREDENTIALS: (state, action) => {
      const { email, password } = action.payload;
      state.defaultCredentials = {
        ...state.defaultCredentials,
        email,
        password,
      };
    },
    RESET_USER_DATA: (state) => {
      state.userData = null;
      state.token = null;
    },
  },
});

const { actions, reducer: AuthReducer } = AuthSlice;

export const {
  SET_USER_DATA,
  SET_TOKEN,
  SET_USER_CREDENTIALS,
  RESET_USER_DATA,
} = actions;

export default AuthReducer;
