const EndPoints = {
  // Login
  login: "/login",

  // Get Dashboard
  getDashboard: "/get-dashboard-data",

  // Get Product
  products: "/products",
  updateProduct: "/products/update-product",
  frequentlyUsedProduct: "/products/frequently_used_product_list",
  favouriteProductList: "/quotations/favourite_product_list",

  //Get User Management
  getUser: "/search-user-list",

  //UserManagement
  addUser: "/add-user",
  deleteUserById: "/delete-user",
  branchDropdown: "/branch-dropdown",
  getRolesDropdown: "/role-dropdown",
  updateUsers: "/update-user",
  //Get Branch list
  getBranches: "/get-branch-list",
  addBranches: `/add-branch`,
  updateBranch: `/update-branch`,

  //ExcelQuotation
  quotationExcelExport: `/quotations/export_excel`,
  quotationExcelExport2: `/quotations/export_excel2`,

  //Get Salesman Dropdown
  getSalesman: "/salesman-dropdown",

  //Get Quotation details
  getQuotationDetails: "/quotations/view_quotation_detail",

  //UPdate Quotation Quote
  updateQuotationQuote: `/quotations/update_quotation`,

  //Export Quotation
  exportQuotation: `/quotations/export_excel`,

  //Delete Product
  deleteProduct: `/products`,

  //Delete Quotations
  deleteQuotation: `/quotations`,

  // Get Quotation List
  quotationList: `/quotations/list`,

  //Add Quotation List
  addQuotation: `/quotations`,

  // Get Quotation Version
  getQuotationVersion: `/quotations/view_quotation_versions`,

  //Create Version
  createQuotationVersion: `/quotations/create_versions`,

  //   Create Quotation
  createBlankQuotation: "/quotations/create_quotation",

  // Get Quotation Client
  getQuotationClient: `/quotations/client_detail`,

  //Get Roles
  getRolesApi: `/roles`,

  //Quotation Client
  updateQuotationClient: `/quotations/update_client`,
  createQuotationClient: `/quotations/create_client`,
  createQuotationSetting: `/quotations/create_setting`,

  // Get Quotation Setting
  getQuotationSetting: `/quotations/view_setting`,

  // Update Quotation Setting
  updateQuotationSetting: `/quotations/quotation_setting_update`,

  //Password
  changePassword: `/change-password`,

  updatePassword: `/update-password`,

  //Export Product
  productsExport: `/products/export_excel`,

  //   Add / Remove Favourite Product
  addOrRemoveFavouriteProduct: "/quotations/add_favourite_product",

  //Forgot Password
  forgotPassword: `/password/email`,

  // Logout
  logout: "/logout",
};

export default EndPoints;
