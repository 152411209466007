import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { getUserIsLoggedInSelector } from "../redux/selectors/AuthSelectors";

const ProtectedRoutes = ({ type = "after_login" }) => {
  const userIsLoggedIn = useSelector(getUserIsLoggedInSelector);
  return type == "after_login" ? (
    userIsLoggedIn ? (
      <Outlet />
    ) : (
      <Navigate to="/login" />
    )
  ) : userIsLoggedIn ? (
    <Navigate to="/" />
  ) : (
    <Outlet />
  );
};

export default ProtectedRoutes;
