import { combineReducers } from "@reduxjs/toolkit";
import { RESET_STORE } from "../types/ReduxTypes";
import AppDefaultReducer from "../defaults/AppDefaultReducer";
import AuthReducer from "./AuthReducer";
import SettingReducer, { branches } from "./SettingReducer";

const appReducer = combineReducers({
  setting: SettingReducer,
  auth: AuthReducer,
});

export default function rootReducer(state, action) {
  let finalState = appReducer(state, action);
  if (action.type == RESET_STORE) {
    const defaultReducer = {
      ...AppDefaultReducer,
    };
    finalState = defaultReducer;
  }
  return finalState;
}
