import React, { Suspense, lazy } from "react";
import TopBarProgress from "react-topbar-progress-indicator";

export const allRoutes = {
  dashboard: "/",
  quotation: "/quotation_details",
  quotationDemo: "/quotation_demo",
  quotationVersion: "/quotation_version",
  quotationClient: "/quotation_client",
  quotationSetting: "/quotation_setting",
  mainQuotation: "/quotations",
  products: "/products",
  userManagement: "/user_management",
  importExport: "/import_export",
  editProduct: "/edit_product",
  forgotPassword: "/forgot_password",
  resetPassword: "/reset_password",
  listItem: "/listItem",
  login: "/login",
  quotationExport: "/quotationExport",
  quotationExcel: "/quotationExcel",
};

const SuspensedView = ({ children }) => {
  TopBarProgress.config({
    barColors: {
      0: "#1F508E",
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

const QuotationDetailPage = lazy(() => import("../pages/quotation_details"));
const Dashboard = lazy(() => import("../pages/dashboard"));
const QuatationVersion = lazy(() => import("../components/quotation_version"));
const ClientDetails = lazy(() => import("../components/quotation_client"));
const QuotationSetting = lazy(() => import("../components/quotation_setting"));
const Products = lazy(() => import("../pages/products"));
const UserManagement = lazy(() => import("../pages/user_management"));
const ImportExport = lazy(() => import("../pages/import_export"));
const EditProducts = lazy(() => import("../pages/edit_products"));
const ForgotPassword = lazy(() => import("../pages/Auth/forgot_password"));
const ListItem = lazy(() => import("../pages/listItem"));
const ResetPassword = lazy(() => import("../pages/Auth/reset_password"));
const MainQuotation = lazy(() => import("../pages/quotations"));
const Login = lazy(() => import("../pages/Auth/login"));
const QuotationDemo = lazy(() => import("../pages/quotation_demo"));
const QuotationExcel = lazy(() => import("../reports/quotationExcel"));

const RoutesPath = [
  {
    path: allRoutes.quotationExcel,
    element: (
      <SuspensedView>
        <QuotationExcel />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.login,
    element: (
      <SuspensedView>
        <Login />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.dashboard,
    element: (
      <SuspensedView>
        <Dashboard />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.quotation,
    element: (
      <SuspensedView>
        <QuotationDetailPage />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.quotationDemo,
    element: (
      <SuspensedView>
        <QuotationDemo />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.quotationVersion,
    element: (
      <SuspensedView>
        <QuatationVersion />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.quotationClient,
    element: (
      <SuspensedView>
        <ClientDetails />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.quotationSetting,
    element: (
      <SuspensedView>
        <QuotationSetting />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.mainQuotation,
    element: (
      <SuspensedView>
        <MainQuotation />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.products,
    element: (
      <SuspensedView>
        <Products />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.userManagement,
    element: (
      <SuspensedView>
        <UserManagement />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.importExport,
    element: (
      <SuspensedView>
        <ImportExport />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.editProduct,
    element: (
      <SuspensedView>
        <EditProducts />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.forgotPassword,
    element: (
      <SuspensedView>
        <ForgotPassword />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.resetPassword,
    element: (
      <SuspensedView>
        <ResetPassword />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.listItem,
    element: (
      <SuspensedView>
        <ListItem />
      </SuspensedView>
    ),
  },
];

export default RoutesPath;
