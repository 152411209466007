import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, useRoutes, Routes, Route } from "react-router-dom";
import Layout from "../components/layout";
import ForgotPassword from "../pages/Auth/forgot_password";
import Login from "../pages/Auth/login";
import ProtectedRoutes from "../protectedRoutes";
import { getUserIsLoggedInSelector } from "../redux/selectors/AuthSelectors";
import { allRoutes } from "./routes";
const Router = () => {
  return (
    <Routes>
      <Route>
        <Route element={<ProtectedRoutes type={"before_login"} />}>
          <Route
            path={allRoutes.forgotPassword}
            name="ForgotPassword"
            element={<ForgotPassword />}
          />
          <Route path={allRoutes.login} name="Login" element={<Login />} />
        </Route>
        <Route element={<ProtectedRoutes type={"after_login"} />}>
          <Route path="*" name="Home" element={<Layout />} />
        </Route>
      </Route>
    </Routes>
  );
};
export default Router;
