import React from "react";
import { Outlet, useRoutes } from "react-router-dom";
import RoutesPath from "../../routes/routes";
import Footer from "./footer";
import Header from "./header";

const RouterOutlet = () => {
  const routes = useRoutes(RoutesPath);
  return routes;
};

const Layout = () => {
  return (
    <>
      <Header />
      <RouterOutlet />
      <Footer />
    </>
  );
};

export default Layout;
