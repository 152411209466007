import { createSlice } from "@reduxjs/toolkit";

export const SettingInitialState = {
  dashboardData: null,
  branches: [],
  roles: [],
};

const SettingSlice = createSlice({
  name: "setting",
  initialState: SettingInitialState,
  reducers: {
    SET_DASHBOARD_DATA: (state, action) => {
      state.dashboardData = action.payload;
    },
    SET_BRANCHS_DATA: (state, action) => {
      state.branches = action.payload;
    },
    SET_ROLES_DATA: (state, action) => {
      state.roles = action.payload;
    },
  },
});

export const { actions, reducer: SettingReducer } = SettingSlice;

export const { SET_BRANCHS_DATA, SET_DASHBOARD_DATA, SET_ROLES_DATA} = actions;

export default SettingReducer;
